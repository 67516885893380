import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import BeatLoader from "react-spinners/BeatLoader";

import {
  Button,
  Description,
  Input,
  SubTitle,
} from "../../Components/CustomComponents";
import { useAddAndUpdateDeliveryAddressMutation } from "../../Data/services/settingsApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/ChangePassword.module.scss";

export default function DeliveryAddress({ data }) {
  // var userData = useSelector((state) => state.auth.user);
  // console.log(`userData = ${userData}`);
  console.log(data);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      country: data ? data.country : "",
      city: data ? data.city : "",
      postalCode: data ? data.areaCode : "",
      region: data ? data.region : "",
      completeAddress: data ? data.completeAddress : "",
    },
  });

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  // useEffect(() => {
  //   if (userData) {
  //     setTimeout(() => {
  //       // console.log("User data:", userData); // Add this log
  //       setValue("city", userData.city);
  //       setValue("country", userData.country);
  //       setValue("postalCode", userData.areaCode);
  //       setValue("region", userData.region);
  //       setValue("completeAddress", userData.completeAddress);
  //     }, 2000);
  //   }
  // }, [userData, setValue, mounted]);

  const [updateAddress, { isLoading }] =
    useAddAndUpdateDeliveryAddressMutation();

  const handleDataSubmission = async (userData) => {
    console.log(userData);
    try {
      // Use unwrap() to get the response userData directly
      const response = await updateAddress(userData);
      console.log(response);

      if (!response.error && response.data.success) {
        SuccessToast(response.data.message);
      }

      if (response.error) {
        Toast(response.error.data.message, "error");
      }
    } catch (error) {
      console.log(error);
      Toast(error?.data?.message, "error");
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div className={styles.ChangePassword}>
      {errors
        ? errors.city?.message
          ? Toast(errors.city?.message, "error")
          : errors.country?.message
          ? Toast(errors.country?.message, "error")
          : errors.postalCode?.message
          ? Toast(errors.postalCode?.message, "error")
          : errors.region?.message
          ? Toast(errors.region?.message, "error")
          : errors.completeAddress?.message
          ? Toast(errors.completeAddress?.message, "error")
          : null
        : null}
      <SubTitle text="Delivery Address" classes="black-text" />
      <Description
        text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        classes="fs-8 mb-4 black-text"
      />
      <Form onSubmit={handleSubmit(handleDataSubmission)}>
        <div className={styles.form}>
          {
            // <Description text="Country" classes="mb-1 black-text" />
          }
          <Controller
            control={control}
            name="country"
            rules={{ required: "Country is required." }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input
                placeholder="Country (e.g. US, AU)"
                type="text"
                ref={ref}
                value={value}
                onChange={onChange}
              />
            )}
          />

          {
            // <Description text="Region" classes="mb-1 black-text" />
          }
          <Controller
            control={control}
            name="region"
            rules={{ required: "Region is required." }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input
                placeholder="State/Province (e.g. NY, TX)"
                type="text"
                value={value}
                ref={ref}
                onChange={onChange}
              />
            )}
          />
          {
            // <Description text="City" classes="mb-1 black-text" />
          }
          <Controller
            control={control}
            name="city"
            rules={{ required: "City is required." }}
            render={({ field: { onChange, value, ref } }) => {
              return (
                <Input
                  placeholder="City"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
          {
            // <Description text="Postal Code" classes="mb-1 black-text" />
          }
          <Controller
            control={control}
            name="postalCode"
            rules={{ required: "Postal code is required." }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input
                placeholder="Postal code"
                type="text"
                value={value}
                ref={ref}
                onChange={onChange}
              />
            )}
          />
          {
            // <Description text="Complete Address" classes="mb-1 black-text" />
          }
          <Controller
            control={control}
            name="completeAddress"
            rules={{ required: "Complete Address is required." }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input
                placeholder="Complete Address"
                type="text"
                value={value}
                ref={ref}
                onChange={onChange}
              />
            )}
          />

          <br></br>
          <div className={styles.saveButton}>
            <Button
              text={isLoading ? <BeatLoader size={10} /> : "Save"}
              classes={`fs-6 mb-2 mt-auto mt-3 mb-2 ${
                isLoading ? "bg-dark-subtle" : ""
              }`}
              disabled={isLoading}
              type="submit"
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
