import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import {
  useAddCategoryMutation,
  useUpdateCategoryMutation,
} from "../../Data/services/categoryApi";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import { Button, CustomInput, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editCategoryModal.module.scss";

const EditCategoryModal = ({
  handleEditModalClose,
  data,
  refetch,
  isCreating,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: data ? data.name : "",
    },
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [rowId, setRowId] = useState("");

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      setRowId(data.id);
      console.log(data);
    }
  }, [data, mounted]);

  // MARK: ADD CATEGORY
  const [addCategory, { isError, isLoading, isSuccess }] =
    useAddCategoryMutation();

  const handleAddCategory = async (data) => {
    console.log("handleAddCategory");
    console.log(data);
    // console.log(data.image[0], "data.image");
    // return;

    if (isSubmitted) return;

    try {
      setIsSubmitted(true);
      const formData = new FormData();
      formData.append("name", data.name);

      if (data.image !== undefined) {
        formData.append("image", data.image[0]);
      }

      // Log the FormData entries to see what is being sent
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      const response = await addCategory(formData);
      console.log(response);

      if (response.data.success) {
        refetch();
        handleEditModalClose();
        Toast(response.data.message, "success");
      }

      setIsSubmitted(false);
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  // MARK: UPDATE CATEGORY
  // useUpdateCategoryMutation
  const [
    updateCategory,
    {
      isError: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
    },
  ] = useUpdateCategoryMutation();

  const handleUpdateCategory = async (data) => {
    console.log("handleUpdateCategory");
    console.log(data);
    console.log(rowId);
    // console.log(data.image[0], "data.image");
    if (isSubmitted) return;
    try {
      setIsSubmitted(true);
      const formData = new FormData();
      formData.append("name", data.name);

      if (data.image !== undefined) {
        formData.append("image", data.image[0]);
      }

      // Log the FormData entries to see what is being sent
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      const id = rowId;
      const response = await updateCategory({ formData, id });
      console.log(response);

      if (response.data.success) {
        refetch();
        Toast(response.data.message, "success");
        handleEditModalClose();
      }

      setIsSubmitted(false);
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">
              {data ? "Edit Category" : "Add Category"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form
              onSubmit={handleSubmit(
                data ? handleUpdateCategory : handleAddCategory
              )}
            >
              <Row>
                <Col md={12}>
                  <p className="m-0">Category Name</p>
                  <Controller
                    control={control}
                    name="name"
                    // defaultValue={""}
                    rules={{
                      required: "Category Name is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mt-2 mb-3"
                        type="text"
                        placeholder="Enter category name"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors?.name?.message ? (
                    <p className="m-0 text-danger text-center">
                      {errors?.name?.message}
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p className="m-0">Category Image</p>
                  <Controller
                    control={control}
                    name="image"
                    // rules={{
                    //   required: "Category Image is required.",
                    // }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <CustomInput
                        classes="mt-2 mb-3"
                        type="file"
                        // value={value}
                        onChange={(e) => {
                          console.log(e.target.files);
                          onChange(e.target.files);
                        }}
                      />
                    )}
                  />
                  {errors?.image?.message ? (
                    <p className="m-0 text-danger text-center">
                      {errors?.image?.message}
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 `}
                    text={
                      isSubmitted ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    // onClick={handleSubmit}
                    type="submit"
                    disabled={isSubmitted}
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditCategoryModal;
