import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { MdDeleteForever } from "react-icons/md";
import { Button, SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";
import { ShimmerTable } from "react-shimmer-effects";

//
import { Col, Row } from "react-bootstrap";
import { FaUserEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import EditPromCodeModal from "../../Components/Modals/EditPromCodeModal";
import {
  useDeletePromoCodeMutation,
  useGetAllPromoCodeQuery,
} from "../../Data/services/promoCodeApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";

const PromoCodeManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const dummyData = [
    {
      image:
        "http://buyatbundles.trangotechdevs.com:3015/uploads/1725884866516-849404233-pexelsmarkusspiske38067531.jpg",
      id: 3,
      name: "Save your budget",
      code: "xs32p93",
      discountPercentage: 5,
      description:
        "Rerum dolor officiis sit alias voluptatum laboriosam soluta consequatur. Molestiae necessitatibus rem aut molestiae deserunt delectus facilis et atque. Tempore aspernatur architecto sed nemo expedita nostrum et enim. Voluptas voluptatum aut et fugiat iste voluptates itaque. Cupiditate facilis voluptatem in et consectetur sit. Ut dolor voluptatem voluptatem nobis.",
      createdAt: "2024-09-09T12:27:47.000Z",
      updatedAt: "2024-09-09T12:27:47.000Z",
    },
  ];

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllPromoCodeQuery({ refetchOnMountOrArgChange: true });

  // console.log(data);

  var promoCodeData = useSelector((state) => state.promoCode.promoCode);
  // console.log(`promoCodeData = ${promoCodeData}`);

  useEffect(() => {
    setRowData(promoCodeData);
  }, [promoCodeData, data]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  // MARK: DELETE
  const [deletePromos, { isLoading: isDeleting }] =
    useDeletePromoCodeMutation();

  const deleteData = async (id) => {
    // console.log(id);
    try {
      const { data } = await deletePromos(id);

      // console.log(data);

      if (data.success) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddPromoCode = () => {
    setEditModal(true);
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={6} />;
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "image",
      text: "Image",
      sort: false,
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.id)
          return (
            <img
              className={styles.cat_image}
              src={row.image}
              width={100}
              height={100}
            />
          );
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        if (row.description) {
          return <div className="lc-2">{row.description}</div>;
        }
      },
    },
    {
      dataField: "discountPercentage",
      text: "Dis.. %",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.discountPercentage) {
          return <div className="fw-bold">{row.discountPercentage} %</div>;
        }
      },
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.code) {
          return (
            <div className="fw-bold p-2 rounded-3 bg-warning">{row.code}</div>
          );
        }
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              {
                <div
                  className={`${styles.btnBg} d-flex justify-content-center align-items-center text-white rounded-3 mx-2 p-2 `}
                  role="button"
                  onClick={() => {
                    setEditedData(row);
                    handleEditModalOpen(true);
                  }}
                >
                  <span
                    className={`align-items-center d-flex justify-content-center pe-1 `}
                  >
                    <FaUserEdit />
                  </span>
                  <span>Edit</span>
                </div>
              }
              <div
                className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      ) : (
        ""
      )}

      {editModal ? (
        <EditPromCodeModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          refetch={refetch}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Promo Code Management"} classes="black-text" />
        </div>
        {
          <Row>
            <Col md={12}>
              <Button
                classes={`${styles.cardBtn} `}
                text="Add Promo Code"
                onClick={handleAddPromoCode}
              ></Button>
            </Col>
          </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default PromoCodeManagementScreen;
