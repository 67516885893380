import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { MdDeleteForever } from "react-icons/md";
import { Button, SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";
import { ShimmerTable } from "react-shimmer-effects";

//
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import EditAlertModal from "../../Components/Modals/EditAlertModal";
import {
  useDeleteAlertsMutation,
  useGetAllAlertsQuery,
} from "../../Data/services/alertsApi";

const AlertManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const dummyData = [
    {
      image:
        "http://buyatbundles.trangotechdevs.com:3015/uploads/1723809015956-660483197-HD-wallpaper-new-whatsapp-dp-plant-whats-app-dp-thumbnail.jpg",
      id: 1,
      heading: "Corporate Infrastructure Assistant",
      description: "Global",
      createdAt: "2024-08-16T11:50:16.000Z",
      updatedAt: "2024-08-16T11:50:16.000Z",
    },
    {
      image:
        "http://buyatbundles.trangotechdevs.com:3015/uploads/1723809015956-660483197-HD-wallpaper-new-whatsapp-dp-plant-whats-app-dp-thumbnail.jpg",
      id: 2,
      heading: "Corporate Infrastructure Assistant",
      description: "Global",
      createdAt: "2024-08-16T11:50:16.000Z",
      updatedAt: "2024-08-16T11:50:16.000Z",
    },
    {
      image:
        "http://buyatbundles.trangotechdevs.com:3015/uploads/1723809015956-660483197-HD-wallpaper-new-whatsapp-dp-plant-whats-app-dp-thumbnail.jpg",
      id: 3,
      heading: "Corporate Infrastructure Assistant",
      description: "Global",
      createdAt: "2024-08-16T11:50:16.000Z",
      updatedAt: "2024-08-16T11:50:16.000Z",
    },
  ];

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllAlertsQuery({ refetchOnMountOrArgChange: true });

  var alertsData = useSelector((state) => state.alerts.alerts);
  // console.log(`alertsData = ${alertsData}`);
  useEffect(() => {
    setRowData(alertsData);
  }, [alertsData]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  // MARK: DELETE
  const [deleteAlert, { isLoading: isDeleting }] = useDeleteAlertsMutation();

  const deleteData = async (id) => {
    console.log(id);

    try {
      const { data } = await deleteAlert({ id: id });

      console.log(data);

      if (data.success) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddCategory = () => {
    setEditModal(true);
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={4} />;
  }

  const columns = [
    {
      dataField: "heading",
      text: "Heading",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "image",
      text: "Image",
      sort: false,
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.id)
          return (
            <img
              className={styles.cat_image}
              src={row.image}
              width={100}
              height={100}
            />
          );
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
    },

    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              {
                // <div
                //   className={`${styles.btnBg} d-flex justify-content-center align-items-center text-white rounded-3 mx-2 p-2 `}
                //   role="button"
                //   onClick={() => {
                //     setEditedData(row);
                //     handleEditModalOpen(true);
                //   }}
                // >
                //   <span
                //     className={`align-items-center d-flex justify-content-center pe-1 `}
                //   >
                //     <FaUserEdit />
                //   </span>
                //   <span>Edit</span>
                // </div>
              }
              <div
                className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      ) : (
        ""
      )}

      {editModal ? (
        <EditAlertModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          refetch={refetch}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Alert Management"} classes="black-text" />
        </div>
        {
          <Row>
            <Col md={12}>
              <Button
                classes={`${styles.cardBtn} `}
                text="Add Alert"
                onClick={handleAddCategory}
              ></Button>
            </Col>
          </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default AlertManagementScreen;
