import React, { useState } from 'react'

import { ROUTES } from "./Route";
import Login from '../Account/Login';
import UserManagementScreen from './UserManagementScreen';

export default function UserManagement() {

    const [page, setPage] = useState(ROUTES.USER_MANAGEMENT)

    switch (page) {
        case ROUTES.USER_MANAGEMENT:
            return <UserManagementScreen setPage={setPage} />
        default:
            return <Login />
    }


}
