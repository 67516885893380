import React, { useMemo } from "react";

import { RiLockPasswordFill } from "react-icons/ri";
import { SiGoogledocs } from "react-icons/si";
import styles from "./styles/Menu.module.scss";

import { FaAddressCard } from "react-icons/fa6";
import { Description, SubTitle } from "../../Components/CustomComponents";
import { ROUTES } from "./contants";

export default function Menu({ route, setRoute }) {
  const OPTIONS = useMemo(() => {
    return [
      {
        heading: "PROFILE SETTINGS",
        options: [
          // {
          //     label: 'Edit Profile',
          //     route: ROUTES.EDIT_PROFILE,
          //     icon: <EditIcon />
          // },
          {
            label: "Change Password",
            route: ROUTES.CHANGE_PASSWORD,
            icon: <RiLockPasswordFill size={20} />,
          },
        ],
      },
      {
        heading: "ORDER & RETURNS",
        options: [
          {
            label: "Return & Exchange",
            route: ROUTES.RETURN_EXCHANGE,
            icon: <SiGoogledocs size={20} />,
          },
          {
            label: "Refund Policy",
            route: ROUTES.REFUND_POLICY,
            icon: <SiGoogledocs size={20} />,
          },
          {
            label: "Cancellation Policy",
            route: ROUTES.CANCELLATION_POLICY,
            icon: <SiGoogledocs size={20} />,
          },
          {
            label: "Delivery Address",
            route: ROUTES.DELIVERY_ADDRESS,
            icon: <FaAddressCard size={20} />,
          },
          {
            label: "Shipping Delivery",
            route: ROUTES.SHIPPING_DELIVERY,
            icon: <SiGoogledocs size={20} />,
          },
        ],
      },
      {
        heading: "APP SUPPORT",
        options: [
          {
            label: "About Us",
            route: ROUTES.ABOUT_US,
            icon: <SiGoogledocs size={20} />,
          },
          // {
          //   label: "Contact Us",
          //   route: ROUTES.CONTACT_US,
          //   icon: <MdMarkEmailRead size={20} />,
          // },
        ],
      },
      {
        heading: "HELP CENTER",
        options: [
          {
            label: "Disclaimer",
            route: ROUTES.DISCLAIMER,
            icon: <SiGoogledocs size={20} />,
          },
          {
            label: "Cookie Policy",
            route: ROUTES.COOKIE_POLICY,
            icon: <SiGoogledocs size={20} />,
          },
          {
            label: "Privacy Policy",
            route: ROUTES.PRIVACY_POLICY,
            icon: <SiGoogledocs size={20} />,
          },
          {
            label: "Terms & Conditions",
            route: ROUTES.TERMS_CONDITIONS,
            icon: <SiGoogledocs size={20} />,
          },
          {
            label: "License Agreement",
            route: ROUTES.LICENSE_AGREEMENT,
            icon: <SiGoogledocs size={20} />,
          },
        ],
      },
    ];
  }, []);

  const handleMenuItemClick = (route) => {
    setRoute(route);
  };

  return (
    <div className={styles.Menu}>
      <SubTitle text="Setting" classes="black-text" />
      <Description
        text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        classes="mt-1 mb-4 fs-8 black-text"
      />
      {OPTIONS.map((section, index) => (
        <div key={index}>
          <h3 className={`mt-4 mb-2 fs-6 primary-text fw-bold`}>
            {section.heading}
          </h3>
          {section.options.map((item, idx) => (
            <div
              className={`${styles.option} my-2 ${
                route === item.route ? styles.active : ""
              }`}
              key={idx}
              onClick={() => handleMenuItemClick(item.route)}
            >
              <span className={styles.icon}>{item.icon}</span>
              <Description text={item.label} classes="fs-7 black-text" />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
