import { createSlice } from "@reduxjs/toolkit";
import { promoCodeApi } from "../services/promoCodeApi";

const initialState = {
  promoCode: [],
};

export const promoCodeSlice = createSlice({
  name: "promoCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        promoCodeApi.endpoints.getAllPromoCode.matchFulfilled,
        (state, { payload }) => {
          state.promoCode = payload.data;
        }
      )
      .addMatcher(
        promoCodeApi.endpoints.getAllPromoCode.matchRejected,
        (state) => {
          state.promoCode = null;
        }
      );
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } =
  promoCodeSlice.actions;

export default promoCodeSlice.reducer;
