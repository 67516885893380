import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { useUpdateFestiveCategoryMutation } from "../../Data/services/festiveCategoryApi";
import { useAddFestiveProductMutation } from "../../Data/services/festiveProductApi";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import { Button } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editCategoryModal.module.scss";

const EditFestiveProductModal = ({
  handleEditModalClose,
  data,
  refetch,
  isCreating,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [rowId, setRowId] = useState("");

  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");

  var festiveCategoryData = useSelector(
    (state) => state.festiveCategory.categories
  );

  const festiveCategoryList = festiveCategoryData.map((item, index) => ({
    value: item.id,
    label: item.category,
  }));

  var festiveProductsData = useSelector((state) => state.products.products);
  // console.log(`festiveProductsData = ${festiveProductsData}`);

  const festiveProductsList = festiveProductsData.map((item, index) => ({
    value: item.id,
    label: item.name,
  }));

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      // console.log(data);
      setRowId(data.id);
      setValue("festiveCategoryId", data.festiveCategoryId);
      setValue("productId", data.productId);
      setSelectedCategoryId(data.type);
      setSelectedProductId(data.type);
    }
  }, [data, mounted]);

  // MARK: ADD CATEGORY
  const [addCategory, { isError, isLoading, isSuccess }] =
    useAddFestiveProductMutation();

  const handleAddCategory = async (data) => {
    console.log("handleAddCategory");
    console.log(data);
    // console.log(data.image[0], "data.image");
    // return;

    if (isSubmitted) return;

    try {
      setIsSubmitted(true);

      const response = await addCategory(data);
      console.log(response);

      if (response.data.success) {
        refetch();
        handleEditModalClose();
        Toast(response.data.message, "success");
      }

      setIsSubmitted(false);
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  // MARK: UPDATE CATEGORY
  // useUpdateCategoryMutation
  const [
    updateCategory,
    {
      isError: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
    },
  ] = useUpdateFestiveCategoryMutation();

  const handleUpdateCategory = async (data) => {
    console.log("handleUpdateCategory");
    console.log(data);
    console.log(rowId);
    // console.log(data.image[0], "data.image");
    if (isSubmitted) return;
    try {
      setIsSubmitted(true);
      // const formData = new FormData();
      // formData.append("name", data.name);

      // if (data.image !== undefined) {
      //   formData.append("image", data.image[0]);
      // }

      // Log the FormData entries to see what is being sent
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}:`, value);
      // }

      const id = rowId;
      const response = await updateCategory({ formData: data, id });
      console.log(response);

      if (response.data.success) {
        refetch();
        Toast(response.data.message, "success");
        handleEditModalClose();
      }

      setIsSubmitted(false);
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">
              {data ? "Edit Festive Product" : "Add Festive Product"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form
              onSubmit={handleSubmit(
                data ? handleUpdateCategory : handleAddCategory
              )}
            >
              <Row>
                <Col md={12}>
                  <p className="m-0">Festive Category</p>
                  <Controller
                    control={control}
                    name="festiveCategoryId"
                    rules={{
                      required: "Select Category.",
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <SelectFieldComponent
                        firstOption={
                          _.isEmpty(selectedCategoryId)
                            ? "Select Category"
                            : selectedCategoryId
                        }
                        optionsList={festiveCategoryList}
                        value={value}
                        // onSelectChange={handleStatusChange}
                        onSelectChange={(value) => {
                          console.log(value);
                          onChange(value);
                          setSelectedCategoryId(value);
                        }}
                      />
                    )}
                  />
                  {errors?.festiveCategoryId?.message && (
                    <p className="m-0 text-danger text-center">
                      {errors?.festiveCategoryId?.message}
                    </p>
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p className="m-0">Product</p>
                  <Controller
                    control={control}
                    name="productId"
                    // defaultValue={""}
                    rules={{
                      required: "Select Product.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <SelectFieldComponent
                        firstOption={
                          _.isEmpty(selectedProductId)
                            ? "Select Product"
                            : selectedProductId
                        }
                        optionsList={festiveProductsList}
                        value={value}
                        // onSelectChange={handleStatusChange}
                        onSelectChange={(value) => {
                          console.log(value);
                          onChange(value);
                          setSelectedProductId(value);
                        }}
                      />
                    )}
                  />
                  {errors?.productId?.message ? (
                    <p className="m-0 text-danger text-center">
                      {errors?.productId?.message}
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 `}
                    text={
                      isSubmitted ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    // onClick={handleSubmit}
                    type="submit"
                    disabled={isSubmitted}
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditFestiveProductModal;
