import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { useAddPromosMutation } from "../../Data/services/promosApi";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import { Button, CustomInput, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editCategoryModal.module.scss";

const EditPromoModal = ({ handleEditModalClose, data, refetch }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({});

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [rowId, setRowId] = useState("");

  // const [selectedStatus, setSelectedStatus] = useState("");

  // const statusList = [
  //   { label: "Active", value: "active" },
  //   { label: "Block", value: "block" },
  // ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      // setRowId(data.id);

      setValue("heading", data.heading);
      setValue("description", data.description);
      setValue("code", data.code);
      console.log(data.role);
    }
  }, [data, mounted]);

  // MARK: ADD PROMOS
  const [addPromo, { isError, isLoading, isSuccess }] = useAddPromosMutation();

  // const handleStatusChange = (value) => {
  //   console.log(value);
  //   setSelectedStatus(value);
  // };

  const handleAddPromo = async (data) => {
    console.log("handleAddPromo");
    console.log(data);
    if (isSubmitted) return;
    try {
      setIsSubmitted(true);
      const formData = new FormData();
      formData.append("heading", data.heading);
      formData.append("code", data.code);
      formData.append("description", data.description);

      if (data.image !== undefined) {
        formData.append("image", data.image[0]);
      }

      // Log the FormData entries to see what is being sent
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      const response = await addPromo(formData);
      console.log(response);

      if (response.data.success) {
        refetch();
        handleEditModalClose();
        Toast(response.data.message, "success");
      }

      setIsSubmitted(false);
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">
              {data ? "Edit Promo" : "Add Promo"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form onSubmit={handleSubmit(handleAddPromo)}>
              <Row>
                <Col md={12}>
                  <p className="m-0">
                    Promo Heading
                    {errors?.heading?.message ? (
                      <span className="ms-2 text-danger text-center">
                        {errors?.heading?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                  <Controller
                    control={control}
                    name="heading"
                    rules={{
                      required: "required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mt-2 mb-3"
                        type="text"
                        placeholder="Enter heading"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p className="m-0">
                    Promo Description
                    {errors?.description?.message ? (
                      <span className="ms-2  text-danger text-center">
                        {errors?.description?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                  <Controller
                    control={control}
                    name="description"
                    rules={{
                      required: "required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mt-2 mb-3"
                        type="text"
                        rows={5}
                        as="textarea"
                        name="description"
                        placeholder="Enter description"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p className="m-0">
                    Promo Code
                    {errors?.code?.message ? (
                      <span className="ms-2 text-danger text-center">
                        {errors?.code?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                  <Controller
                    control={control}
                    name="code"
                    rules={{
                      required: "required",
                      pattern: {
                        value: /^[A-Za-z0-9]{6}$/,
                        message:
                          "Code must be 6 characters long and contain only letters and numbers",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mt-2 mb-3"
                        type="text"
                        name="code"
                        placeholder="Enter code"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p className="m-0">Promo Image</p>
                  <Controller
                    control={control}
                    name="image"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <CustomInput
                        classes="mt-2 mb-3"
                        type="file"
                        onChange={(e) => {
                          console.log(e.target.files);
                          onChange(e.target.files);
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 `}
                    text={
                      isSubmitted ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    // onClick={handleSubmit}
                    type="submit"
                    disabled={isSubmitted}
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditPromoModal;
